.cd_impressum{
    padding: 20px;
}

.cd_impressum p{
    margin: 0;
}

.cd_divider{
    height: 100px;
}