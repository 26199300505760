header {
  display: flex;
  justify-content: center;
}

#cd_header_logo {
  width: 70%;
  max-width: 400px;
  padding: 30px 0px;
}

#cd_header_logo > img{
  width: 100%;
  height: 100%;
}

nav {
  position: sticky;
  top: 0;
  z-index: 1;
}

#cd_header_links > a {
  padding: 5px 10px;
  color: #39d0db;
  text-decoration: none;
  font-weight: bold;
}

#cd_header_links > a:hover {
  color: #222;
}

#cd_header_links > a.cd_selected_link{
  color: #222;
}

#cd_header_toggle {
  outline: none;
  border: none;
  font-size: large;
  font-weight: bold;
  background-color: white;
}

@media (min-width: 45em) {
  #cd_header_links {
    display: flex;
    flex-wrap: wrap;
  }

  nav {
    display: grid;
    grid-template-columns: 50px 600px 1fr;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 100px;
  }

  #cd_header_l {
    height: 30px;
    width: 100%;
    background-color: #202020;
  }

  #cd_header_r {
    height: 30px;
    display: grid;
    grid-template-columns: 1fr 30px 30px 30px 30px;
  }

  #cd_header_toggle {
    display: none;
  }
}

@media (max-width: 45em) {
  #cd_header_links.hidden {
    display: none;
  }

  #cd_header_links {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 49px;
    background-color: white;
    width: 100%;
    padding: 30px 0px;
  }

  #cd_header_links > a {
    padding-bottom: 10px;
    padding-left: 30px;
  }

  nav {
    display: grid;
    grid-template-columns: 17px 1fr 60%;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    height: 50px;
  }

  #cd_header_l {
    height: 15px;
    width: 100%;
    background-color: #202020;
  }

  #cd_header_r {
    height: 15px;
    display: grid;
    grid-template-columns: 1fr 30px 30px 30px 30px;
    width: 100%;
  }
}

#cd_header_color0 {
  width: 100%;
  background-color: #202020;
}
#cd_header_color1 {
  width: 30px;
  background-color: #126c73;
}
#cd_header_color2 {
  width: 30px;
  background-color: #ff5cbf;
}
#cd_header_color3 {
  width: 30px;
  background-color: #ffe229;
}
#cd_header_color4 {
  width: 30px;
  background-color: #ffe854;
}
