#cd_courses_wrapper {
  max-width: 1200px;
  margin: auto;
  padding: 0px 30px;
  padding-bottom: 200px;
  position: relative;
}

.cd_courses_wrapper_evening {
  padding-top: 100px;
  padding-bottom: 200px;
}

.cd_courses_dayCol {
  flex-grow: 1;
  flex-shrink: 1;
}

.cd_courses_dayCol > h3{
  font-family: Sen;
}

#cd_courses_week_selection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0px;
}

#cd_courses_week_selection > button {
  background-color: #202020;
  color: white;
  font-weight: bold;
  width: 50px;
  border-radius: 40px;
  height: 35px;
  border: 0;
}

#cd_courses_week_selection > p {
  font-weight: bold;
}

@media (max-width: 45em) {
  #cd_courses_week_selection {
    position: sticky;
    top: 40px;
    bottom: 0;
    background-color: white;
    width: 100%;
  }
}


@media (min-width: 45em) {
  #cd_courses_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    justify-content: center;
    padding-top: 100px;
  }
  #cd_courses_week_selection {
    position: absolute;
    width: 100%;
  }
}