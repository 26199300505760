#cd_footer {
  width: 100%;
  background-color: #202020;
  color: white;
}

.cd_flat_link {
  color: white;
}

#cd_footer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

#cd_footer > .footer_logo_div {
  align-items: center;
}

#cd_footer > .footer_logo_div > img{
  max-width: 100px;
}

.cd_footer_flex {
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: space-around;
  column-gap: 30px;
}

#cd_footer_download {
  padding: 10px;
  border: 1px solid white;
}

.cd_footer_link {
  padding: 10px 20px;
  color: #202020;
}

@media (min-width: 45em) {
  #cd_footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.cd_social_icon {
  width: 40px;
  padding: 10px;
}

#cd_footer > div > h2 {
  text-align: center;
}
