@import url(https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
html{
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  color: #126c73;
}

h1,h2,h3{
  font-family: Sen;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  display: flex;
  justify-content: center;
}

#cd_header_logo {
  width: 70%;
  max-width: 400px;
  padding: 30px 0px;
}

#cd_header_logo > img{
  width: 100%;
  height: 100%;
}

nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

#cd_header_links > a {
  padding: 5px 10px;
  color: #39d0db;
  text-decoration: none;
  font-weight: bold;
}

#cd_header_links > a:hover {
  color: #222;
}

#cd_header_links > a.cd_selected_link{
  color: #222;
}

#cd_header_toggle {
  outline: none;
  border: none;
  font-size: large;
  font-weight: bold;
  background-color: white;
}

@media (min-width: 45em) {
  #cd_header_links {
    display: flex;
    flex-wrap: wrap;
  }

  nav {
    display: grid;
    grid-template-columns: 50px 600px 1fr;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 100px;
  }

  #cd_header_l {
    height: 30px;
    width: 100%;
    background-color: #202020;
  }

  #cd_header_r {
    height: 30px;
    display: grid;
    grid-template-columns: 1fr 30px 30px 30px 30px;
  }

  #cd_header_toggle {
    display: none;
  }
}

@media (max-width: 45em) {
  #cd_header_links.hidden {
    display: none;
  }

  #cd_header_links {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 49px;
    background-color: white;
    width: 100%;
    padding: 30px 0px;
  }

  #cd_header_links > a {
    padding-bottom: 10px;
    padding-left: 30px;
  }

  nav {
    display: grid;
    grid-template-columns: 17px 1fr 60%;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    height: 50px;
  }

  #cd_header_l {
    height: 15px;
    width: 100%;
    background-color: #202020;
  }

  #cd_header_r {
    height: 15px;
    display: grid;
    grid-template-columns: 1fr 30px 30px 30px 30px;
    width: 100%;
  }
}

#cd_header_color0 {
  width: 100%;
  background-color: #202020;
}
#cd_header_color1 {
  width: 30px;
  background-color: #126c73;
}
#cd_header_color2 {
  width: 30px;
  background-color: #ff5cbf;
}
#cd_header_color3 {
  width: 30px;
  background-color: #ffe229;
}
#cd_header_color4 {
  width: 30px;
  background-color: #ffe854;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1080px;
  margin: auto;
}

.cd_home_text {
  padding: 30px 30px;
}

.cd_home_text > h2 {
  font-family: "Sen", sans-serif;
}

.cd_home_block {
  margin: auto;
  text-align: justify;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}

.cd_home_block > div > p {
  max-width: 700px;
}

.cd_home_block > img {
  width: 100%;
  display: block;
}

@media (min-width: 45em) {
  .img_left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }

  .img_left > div > p {
    max-width: 400px;
  }

  .img_left > img {
    order: -1;
  }

  .img_right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }

  .img_right > div > p {
    max-width: 400px;
  }

  .cd_content_divider {
    height: 50px;
  }
}

@media (max-width: 45em) {
  .cd_content_divider {
    height: 20px;
  }
}

#cd_news_wrapper{
    background-color: #efefef;
    padding: 30px;
    position: relative;
    box-shadow: inset 0px 1px 9px 1px #dadada;
}

#cd_news_wrapper > div{
    max-width: 1080px;
    margin: auto;
    position: relative;
}

#cd_news_heading{
    font-family: Sen;
}

#news_button{
    color: black;
    background-color: rgba(0,0,0,0);
    border: none;
    position: absolute;
    right: 10px;
    font-size: x-large;
    font-weight: bold;
}
#cd_footer {
  width: 100%;
  background-color: #202020;
  color: white;
}

.cd_flat_link {
  color: white;
}

#cd_footer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

#cd_footer > .footer_logo_div {
  align-items: center;
}

#cd_footer > .footer_logo_div > img{
  max-width: 100px;
}

.cd_footer_flex {
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: space-around;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

#cd_footer_download {
  padding: 10px;
  border: 1px solid white;
}

.cd_footer_link {
  padding: 10px 20px;
  color: #202020;
}

@media (min-width: 45em) {
  #cd_footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.cd_social_icon {
  width: 40px;
  padding: 10px;
}

#cd_footer > div > h2 {
  text-align: center;
}

#cd_courses_wrapper {
  max-width: 1200px;
  margin: auto;
  padding: 0px 30px;
  padding-bottom: 200px;
  position: relative;
}

.cd_courses_wrapper_evening {
  padding-top: 100px;
  padding-bottom: 200px;
}

.cd_courses_dayCol {
  flex-grow: 1;
  flex-shrink: 1;
}

.cd_courses_dayCol > h3{
  font-family: Sen;
}

#cd_courses_week_selection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0px;
}

#cd_courses_week_selection > button {
  background-color: #202020;
  color: white;
  font-weight: bold;
  width: 50px;
  border-radius: 40px;
  height: 35px;
  border: 0;
}

#cd_courses_week_selection > p {
  font-weight: bold;
}

@media (max-width: 45em) {
  #cd_courses_week_selection {
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
    bottom: 0;
    background-color: white;
    width: 100%;
  }
}


@media (min-width: 45em) {
  #cd_courses_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    justify-content: center;
    padding-top: 100px;
  }
  #cd_courses_week_selection {
    position: absolute;
    width: 100%;
  }
}
.cd_courses_course {
    background-color: white;
    border: 2px solid #39d2dc;
    color: #202020;
    font-size: small;
    padding: 10px;
    margin: 4px 2px;
    border-bottom-right-radius: 20px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  
  .cd_courses_course:hover {
    border: 2px solid #fe5bbe;
  }
  
 .cd_active_course{
    padding: 10px;
    border: 2px solid #fe5bbe;
 }
  
  .cd_course_name {
    margin-bottom: 0;
    font-weight: bold;
  }
  .cd_course_time {
    margin-top: 0;
  }




.cd_impressum{
    padding: 20px;
}

.cd_impressum p{
    margin: 0;
}

.cd_divider{
    height: 100px;
}
.cd_datenschutz{
    max-width: 1080px;
    padding: 30px;
}
