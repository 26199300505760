.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1080px;
  margin: auto;
}

.cd_home_text {
  padding: 30px 30px;
}

.cd_home_text > h2 {
  font-family: "Sen", sans-serif;
}

.cd_home_block {
  margin: auto;
  text-align: justify;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}

.cd_home_block > div > p {
  max-width: 700px;
}

.cd_home_block > img {
  width: 100%;
  display: block;
}

@media (min-width: 45em) {
  .img_left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }

  .img_left > div > p {
    max-width: 400px;
  }

  .img_left > img {
    order: -1;
  }

  .img_right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }

  .img_right > div > p {
    max-width: 400px;
  }

  .cd_content_divider {
    height: 50px;
  }
}

@media (max-width: 45em) {
  .cd_content_divider {
    height: 20px;
  }
}
