.cd_courses_course {
    background-color: white;
    border: 2px solid #39d2dc;
    color: #202020;
    font-size: small;
    padding: 10px;
    margin: 4px 2px;
    border-bottom-right-radius: 20px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  
  .cd_courses_course:hover {
    border: 2px solid #fe5bbe;
  }
  
 .cd_active_course{
    padding: 10px;
    border: 2px solid #fe5bbe;
 }
  
  .cd_course_name {
    margin-bottom: 0;
    font-weight: bold;
  }
  .cd_course_time {
    margin-top: 0;
  }